
:root {
	--primary: #008DC9;
	--accent-1: #7D4300;
	--accent-2: #C96C00;
}

body {
	font-family: 'Unbounded', Verdana, Geneva, Tahoma, sans-serif;
}

.page {
	margin-top: var(--navbar-height, 0);
	min-height: calc(var(--window-height) - var(--navbar-height));
}

.fill-container, .fill-parent {
	height: 100%;
	width: 100%;
}


.vh-align, .vhalign {
	display: flex;
	align-items: center;
	justify-content: center;
}

.v-align, .valign {
	display: flex;
	align-items: center;
}

.h-align, .halign {
	display: flex;
	justify-content: center;
}

.center-align {
	text-align: center;
}

.right-align {
	text-align: right;
}

.grey-text {
	color: gray;
}


.primary-text {
	color: var(--primary);
}

.primary-bg {
	background-color: var(--primary);
}

h1, h2, h3, h4, h5, h6 {
	color: var(--primary);
}
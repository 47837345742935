
@keyframes bounce {
   0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
   40% {transform: translateY(-30px);} 
   60% {transform: translateY(-15px);} 
}

.bounce { 
   -webkit-animation-name: bounce; 
   animation-name: bounce;
   -webkit-animation-duration: 1s;
   animation-duration: 1s; 
   -webkit-animation-fill-mode: both; 
   animation-fill-mode: both;
   -webkit-animation-iteration-count: infinite;
   animation-iteration-count: infinite;
}

@keyframes pulse {
   0% {
       transform: scale(1.0);
   }

   50% {
      transform: scale(1.01);
   }

   100% {
       
      transform: scale(1);
   }
}

.pulse { 
   --duration: 1s;
   -webkit-animation-name: pulse; 
   animation-name: pulse;
   -webkit-animation-duration: var(--duration);
   animation-duration: var(--duration); 
   -webkit-animation-fill-mode: both; 
   animation-fill-mode: both;
   -webkit-animation-iteration-count: infinite;
   animation-iteration-count: infinite;
}